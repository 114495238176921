import React from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import "./index.css"

const Aboutus=()=>(
    <div>
      {document.title = "About Us | MoonProduct"}
      <Menu/>
      <div className="header1"></div>
      <div className="section">
        <div className="AboutMe-section">
          <h1>Who we are?</h1>
          <p>MoonProduct is focused on growing entrepreneurship and we believe entrepreneurs will change the world for better. We want to be the positive catalyst for 53 million businesses and over 600 million internet users in India.</p>
        </div>
        {/* <div className="Education-section">  
          <h1>Langauage uses</h1>
          <p>React</p>
        </div> */}
      </div>
      <Footer/>
    </div>
)
export default Aboutus;