import React from 'react';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import  card1 from "../../images/Top Business Card.png";
import CtaViral from '../../components/cta-viral';
// import "./index.scss";
const Resources = () =>(
<div>
    <Menu />
    <br></br>
    <br></br>
    <div className="body1">
      <div className="blog-card alt">
        <div className="meta">
          <div className="photo1" ></div>
          <ul className="details">
            <li className="author">DevMaster:Vivek</li>
            <li className="date">Jan. 22, 2020</li>
            {/* <li className="tags">
              <ul>
                <li><a href="#">Quora</a></li>
                <li><a href="#">Linkedin</a></li>
                <li><a href="#">Facebook</a></li>
              </ul>
            </li> */}
          </ul>
          </div>
          <div className="description">
            <h1>What is SEO Optimization?</h1>
            <h2>Will it bring more Customers? Sales?</h2>
            <p>If you want more leads, sales, customers for FREE, do SEO optimization.</p>
            <p className="read-more">
              <a href="/resources/seo-optimization">Read More</a>
            </p>
          </div>
      </div>
      <div className="blog-card">
        <div className="meta">
          <div className="photo"></div>
          <ul className="details">
            <li className="author">Juinor-Marketing:Divya</li>
            <li className="date">Dec. 01, 2019</li>
            {/* <li className="tags">
              <ul>
                <li><a href="#">Quora</a></li>
                <li><a href="#">Linkedin</a></li>
                <li><a href="#">Facebook</a></li>
              </ul>
            </li> */}
          </ul>
        </div>
        <div className="description">
          <h1>Business Cards / Visiting Cards</h1>
          <h2>What to put on a business card?</h2>
          <p> A Good Business Card is not just a tool for giving your information. Its an extension of your brand that conveys the right information of you. So, here is the tip to design a basic Business Card.</p>
          <p className="read-more">
            <a href="/resources/business-cards">Read More</a>
          </p>
        </div>
      </div>
    </div>
    <Footer/> 
</div>
);
export default Resources;