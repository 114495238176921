import React from 'react';
import './index.css'
import logo from '../../logo.svg';
import heart from "../../images/heart.svg";
const Footer = props => (
    <div className="footer">
        <div className="container footer-content" style={{color:"#262C36"}}>
            <ul>
                <li>
                    <a  style={{display:"flex",justifyContent:"center"}} href="/"><img src={logo}/></a>
                    <p style={{textAlign:"center",fontSize:"10px",Color:"blue"}}> Made with <img  style={{height:"14px"}}src={heart}/>  in India</p>
                </li>                
                <li>
                    <p className="bold">Address</p>
                    <p style={{maxWidth:300,color:"#979797",fontSize:14}}>91SpringBoard, 5th Floor, Dollars Colony, Phase 4, JP Nagar, Bannerghatta Main Road, Bengaluru, Karnataka 560076</p>
                    <p style={{maxWidth:300,color:"#979797",fontSize:14}}>IndiQube Aerial, Ground Floor, Silicon Terraces, 30/1, Hosur Rd, Koramangala, Bengaluru, Karnataka 560095</p>
                    <p style={{maxWidth:300,color:"#979797",fontSize:14}}><a href="https://goo.gl/maps/yPrBxeWnabL9zoi6A" target="_blank">View Map</a></p>
                    {/* <p><a href="#our-services">Datacenter Consultancy services</a></p>
                    <p><a href="#our-solutions">Software solutions & consultancy service</a>s</p> */}
                </li>
                <li>
                    <p className="bold">Company</p>
                    <p><a href="/careers" style={{maxWidth:300,color:"#979797",fontSize:14}}>Careers</a></p>
                    <p><a href="/about-us" style={{maxWidth:300,color:"#979797",fontSize:14}}>About Us</a></p>
                    <p><a href="/contact-us" style={{maxWidth:300,color:"#979797",fontSize:14}}>Contact Us</a></p>
                    <p><a href="/privacy-policy" style={{maxWidth:300,color:"#979797",fontSize:14}}>Privacy Policy</a></p>
                    <p><a href="/terms-and-conditions" style={{maxWidth:300,color:"#979797",fontSize:14}}>Terms and Conditions</a></p>
                    <p><a href="/refund-policy" style={{maxWidth:300,color:"#979797",fontSize:14}}>Cancellations or Refund Policy</a></p>
                </li>
            </ul>  
            {/* <hr></hr> */}
            <ul>
                <li style={{color:'#000',fontSize:10,textAlign:"center"}}>Copyrights @ MoonProduct</li>
            </ul>          
        </div>
    </div>
);

export default Footer;