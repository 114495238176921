import React,{Component} from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import whatsapp from "../../images/whatsapp.svg";
import email from "../../images/email.svg";
import building from "../../images/building.svg";
import skyline from "../../images/skyline.svg";
import "./index.css";
class Contact extends Component {
constructor(props){
  super(props);
  this.state ={
    name:'',
    email:'',
    phone:'',
    message:''
  }
}


render(){
return(
  <div>
    {document.title = "Contact Us | MoonProduct"}
    <Menu/>
    {/*******Form Section******/}
    {/* <div className="contact-section">

    <h1>Contact Us</h1>
    <div className="border"></div>
    <form className="contact-form" >
      <input type="text" className="contact-form-text" placeholder="Your name"/>
      <input type="email" className="contact-form-text" placeholder="Your email"/>
      <input type="text" className="contact-form-text" placeholder="Your phone"/>
      <textarea className="contact-form-text" placeholder="Your message"></textarea>
      <input type="submit" className="contact-form-btn" value="Send"/>
    </form>
    </div> */}
    <div className="header" style={{backgroundColor:"#e6ffe6"}}>
    <h1 className="heading"> GET IN TOUCH
    </h1>
      <p className="intro">We would love to hear  from you</p>
      <div>
      <div className="card">
        <img src={whatsapp} style={{height:"40px",width:"46px",marginTop:"20px",marginLeft:"90px"}}/>
        <p style={{textAlign:"center"}}>We are always available</p>
        <a href="tel:+919791848091" style={{color:"blue",marginLeft:"50px",textDecoration:"none"}}>+919791848091</a>
      </div>
      <div className="card">
      <img src={email} style={{height:"40px",width:"46px",marginTop:"20px",marginLeft:"90px"}}/>
        <p style={{textAlign:"center"}}>Ping me on mail</p>
        <a  style={{color:"blue",marginLeft:"40px",textDecoration:"none"}}>contact@moonproduct.in</a>
      </div>
      <div className="card">
      <img src={building} style={{height:"40px",width:"46px",marginTop:"10px",marginLeft:"90px"}}/>
        <p style={{textAlign:"center"}}> Head  Office</p>
        <p  style={{color:"blue",textAlign:"center"}}>91SpringBoard</p><p style={{color:"blue",textAlign:"center"}}>5th Floor,Dollars Colony,Phase-4 jpnagar,Bengaluru,Karnataka 560076</p>
      </div>
      <div className="card">
      <img src={skyline} style={{height:"40px",width:"46px",marginTop:"10px",marginLeft:"90px"}}/>
        <p style={{textAlign:"center"}}>Branch  Office</p>
        <p  style={{color:"blue",textAlign:"center"}}>IndiQube Aerial</p><p style={{color:"blue",textAlign:"center"}}>Ground Floor, Silicon Terraces, 30/1, Hosur Rd, Koramangala, Bengaluru, Karnataka 560095</p>
      </div>
      </div>
      </div>
      <div style={{backgroundColor:"#e6ffe6"}}>
      </div>
      <Footer/>
    </div>
)
}
}
export default Contact;