import React from 'react';
import Menu from '../../components/menu';
import Footer from '../../components/footer';

const Seo = () => (
    <div>
        {document.title = "What is SEO Optimization? More Customers? Sales? Explained in 5 minutes, Simple Words for any Company | MoonProduct"}
        {document.description = "Spend 5 minutes and practically learn how to get your customer visit you site with this no tech jargon, simple steps, explained using simple words for anyone to do SEO optimsation or Search Engine Optimization."}
        <Menu/>
        <div style={{textAlign:"left",backgroundColor:"white"}}>
            <div className="container paddingTop">
                <h1>What is SEO Optimization? Will it bring more Customers? Sales?</h1>
                <p>If you want more leads, sales, customers for FREE, do SEO optimization.</p>
                <h2>Step 1: Find Keywords</h2>
                <p>Example: Assume that you've started an Insurance Company like <a href="https://www.godigit.com/">GoDigit</a>. When anyone searches for insurance, you want your site to appear in top 3 search result for free. Think what your customer types in Google Search. This is very important for SEO optimization. Any mistake here, other steps are waste of time. </p>
                <ul>
                    <li>Head Keyword - 1 or 2 words like "Insurance"</li>
                    <li>Body Keyword - 2 or 3 words like "Bike Insurance"</li>
                    <li>Long Tail Keyword - group of words like "Insurance for Royal Enfield Thunderbird"</li>
                </ul>
                <p>It is very difficult to rank for Head and Body Keyword in most of the scenarios. Try to type in Google and check results. It is easy to rank higher in Long Tail Keyword. Make sure that there is signification searches by Google Search users for Long Tail Keyword.</p>
                <h2>Step 2: Create pages for keywords</h2>
                <p>Use keyword 5 to 10 times in the page for better seo optimization. Follow this 6 things while creating pages</p>
                <ol>
                    <li>URL - Should have keyword. Make it as short as possible.</li>
                    <li>Meta - Add keyword in description </li>
                    <li>h1 - Title of the page has to be inside h1 tag.</li>
                    <li>alt - Write alt tag with keyword if possible</li>
                    <li>p - Use keyword in first 100 - 150 words of paragraph tag.</li>
                    <li>h2 - Heading or Sub-Heading goes here. Use keyword at least once in the bottom of the page.</li>
                    <li>a - Give reference to High Autority Site</li>
                </ol> 
                <h2>Step 3: Post the pages in relevant other sites</h2>    
                <p>Site like <a href="http://quora.com">Quora</a>, <a href="https://www.linkedin.com">LinkedIn</a>, <a href="https://www.facebook.com/">Facebook</a> </p>           
            </div>
        </div>
        <Footer/> 
        {/* <div className="sticky">
            <CtaViral1 content={"Need help in designing and communicating with your visiting card?"} cta={"GET FREE VISTING CARD"} link={""}/>
        </div>  */}

    </div>
);

export default Seo;