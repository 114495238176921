import React from 'react';
import Menu from '../../components/menu';
import Footer from '../../components/footer';

const Seo = () => (
    <div>
        {document.title = "What is SEO Optimization? More Customers? Sales? Explained in 5 minutes, Simple Words for any Company | MoonProduct"}
        {document.description = "Spend 5 minutes and practically learn how to get your customer visit you site with this no tech jargon, simple steps, explained using simple words for anyone to do SEO optimsation or Search Engine Optimization."}
        <Menu/>
        <div style={{textAlign:"left",backgroundColor:"white"}}>
            <div className="container paddingTop">
                <h1>How to get leads for your start-up?</h1>
                <p>When we started MoonProduct, we spent most of the time in technology. But the correct way to do is to spend at least some amount of effort in marketing first and then sales.</p>       
            </div>
        </div>
        <Footer/> 
        {/* <div className="sticky">
            <CtaViral1 content={"Need help in designing and communicating with your visiting card?"} cta={"GET FREE VISTING CARD"} link={""}/>
        </div>  */}

    </div>
);

export default Seo;