import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './screens/home';
import Careers from './screens/careers';
import Aboutus from './screens/Aboutus';
import Contact from "./screens/contactus"
import Terms from "./screens/termsandconditions";
import Policy from "./screens/privacypolicy";
import Cancel from "./screens/cancellations";
import Resources from "./screens/resources";
import Business from './screens/business';
import Seo from './screens/seo';
import Leads from './screens/leads';
import Contactuspage from './screens/contactuspage';
import Facebookshop from './screens/facebookshop';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/careers" component={Careers}/>
          <Route path="/about-us" component={Aboutus}/>
          <Route path="/contact-us" component={Contact}/>
          <Route path="/terms-and-conditions" component={Terms}/>
          <Route path="/privacy-policy" component={Policy}/>  
          <Route path="/refund-policy" component={Cancel}/>   

          <Route path="/resources-listing" component={Resources}/>
          <Route path="/resources/business-cards" component={Business}/> 
          <Route path="/resources/seo-optimization" component={Seo}/> 
          <Route path="/resources/leads" component={Leads}/>
          <Route path="/resources/contact-us-page-in-html" component={Contactuspage}/>
          <Route path="/resources/how-to-set-up-facebook-shop" component={Facebookshop}/>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
