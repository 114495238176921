import React from 'react';
import Menu from '../../components/menu';
import Banner from '../../components/banner';
import Footer from '../../components/footer';
import Cta from '../../components/cta';
import Client from '../../components/clients';
// import Reviews from '../../components/reviews';

import proser1 from '../../images/proser1.svg';
import proser2 from '../../images/proser2.svg';
import proser3 from '../../images/proser3.svg';
import proser4 from '../../images/proser4.svg';
import proser5 from '../../images/proser5.svg';
import proser6 from '../../images/proser6.svg';
import arrow from '../../images/arrow.svg';

const Home = () => (
    <div>
        <Menu/>
        <Banner/>
        {/* <div style={{backgroundColor:"#4053AE"}}>
            <a href="https://hackathon.moonproduct.in/" target="_blank" style={{textDecoration:"none"}}>
                <p className="font16"style={{textAlign:"center",color:"#ffffff",margin:"-1px",padding:4 }}>Front-end developers! Apply asap. Countdown started. Selected 100 will get an e-mail on Feb 22nd 2020. 2 Rounds. 3 Winners. Participate in Scriptathon!! REGISTER NOW</p>
            </a> 
        </div>  */}
        <a name="what-we-do"/>
        <div className="secondaryBackground" style={{textAlign:"center"}}>
                <div className="container padding64">
                    <p className="font29" style={{color:"#262C36"}}>What we do?</p>
                    <p className="font21">We wish to be your guide, partner and friend in your journey, from ideation to acquiring customers.<br/><br/></p>
                    <div style={{display:"flex",flexWrap:"wrap"}}>
                        <div className="moonadvantage">
                            <img src={proser1} style={{height:220}}/>
                            <p className="font21 headingColor"><strong>B2B eCommerce</strong></p>
                            <p className="font19 secondaryColor">Business to Business Sales happen differently. We've built SAAS product TeVe to enable it.</p> 
                            <a href="http://teve.moonproduct.in" target="_blank" style={{textDecoration:"none",color:"#4053AE"}}><button style={{backgroundColor:"white",color:"#4053AE",margin:10,border:"none"}}className="button" >VIEW TEVE  <img src={arrow} style={{verticalAlign:"bottom",height:16}}/></button></a>
                        </div>
                        <div className="moonadvantage">
                            <img src={proser6} style={{height:220}}/>
                            <p className="font21 headingColor"><strong>Service Booking</strong></p>
                            <p className="font19 secondaryColor">Grow your service company by 10X. SEO optimized website. Lead management app</p>
                            <a href="http://leads.moonproduct.in" target="_blank" style={{textDecoration:"none",color:"#4053AE"}}><button style={{backgroundColor:"white",color:"#4053AE",margin:10,border:"none"}}className="button" >VIEW LEADS  <img src={arrow} style={{verticalAlign:"bottom",height:16}}/></button></a>       
                        </div>   
                        <div className="moonadvantage">
                            <img src={proser3} style={{height:220}}/>
                            <p className="font21 headingColor"><strong>Development</strong></p>
                            <p className="font19 secondaryColor">We use React/ Flutter to develop cross-platform mobile and web applications.</p>                    
                        </div>   
                        <div className="moonadvantage">
                            <img src={proser5} style={{height:220}}/>
                            <p className="font21 headingColor"><strong>B2C eCommerce</strong></p>
                            <p className="font19 secondaryColor">If you are serious about Shopify store and its online success, we're the Partner for you.</p>   
                            <a href="https://www.shopify.in" target="_blank" style={{textDecoration:"none",color:"#4053AE"}}><button style={{backgroundColor:"white",color:"#4053AE",margin:10,border:"none"}}className="button" >VIEW SHOPIFY  <img src={arrow} style={{verticalAlign:"bottom",height:16}}/></button></a>    
                        </div>                                                                        
                        <div className="moonadvantage">
                            <img src={proser2} style={{height:220}}/>
                            <p className="font21 headingColor"><strong>Design</strong></p>
                            <p className="font19 secondaryColor">We design creative UI/UX for ANDROID, iOS and Web applications.</p>
                            <a href="http://designs.moonproduct.in" target="_blank" style={{textDecoration:"none",color:"#4053AE"}}><button style={{backgroundColor:"white",color:"#4053AE",margin:10,border:"none"}}className="button" >VIEW DESIGNS  <img src={arrow} style={{verticalAlign:"bottom",height:16}}/></button></a> 
                        </div>
                        <div className="moonadvantage">
                            <img src={proser4} style={{height:220}}/>
                            <p className="font21 headingColor"><strong>Growth Hacking</strong></p>
                            <p className="font19 secondaryColor">Want to Grow? Is your marketing designed for growth? Check it out</p>       
                        </div>                       
                    </div>
                </div>
            </div> 
        <Client/>
        {/* <Reviews/>
        <div className="carousel-container"></div> */}
        <Cta content={"You are here! And, that means you are the one striving hard to make a dent. But building and launching products that creates a real-impact is not easy."} cta={"GET CONNECTED"} link={"https://calendly.com/moonproduct/15min"}/>      
        <Footer/>
    </div>
);

export default Home;