import React from 'react';
import './index.css';
import banner from '../../images/banner.svg';

const Banner = props => (
    <div className="banner" style={{backgroundImage:banner}}>
        <a name="home"></a>
        <div className="bold container banner-content center">
            {/* <p style={{color:"white",paddingTop:36}} className="banner-text">We help startups <br/>who dare to change the world.</p> */}
            <p style={{color:"white",paddingTop:36}} className="banner-text">We help Business Owners <br/>to accelerate their growth 📈</p>            
            {/* <p className="banner-cta" style={{color:"white"}}>We wish to be your guide, partner and friend in your journey, from ideation to acquiring customers.</p> */}
            <a href="https://calendly.com/moonproduct/15min "><button className="button" type="button" style={{backgroundColor:"white",color:"#262C36"}}>Get Connected</button></a>  
        </div>
    </div>
);

export default Banner;