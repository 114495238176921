import React from 'react';
import Menu from '../../components/menu';
import Banner from '../../components/banner';
import Footer from '../../components/footer';
import '../careers/index.css';
import advantage1 from '../../images/Work-Anywhere.svg';
import advantage2 from '../../images/Build-your-own-idea.svg';
import advantage3 from '../../images/Intensive-learning.svg';
import advantage4 from '../../images/Be-a-teacher.svg';
import advantage5 from '../../images/Run-a-startup.svg';
import advantage6 from '../../images/Be-a-leader.svg';
import joinMoonProduct from '../../images/join-moonproduct.svg';
import WhatsApp from '../../images/WhatsApp.png';

const Home = () => (
    <div>
        {document.title = "Careers | MoonProduct"}
        <Menu/>
        <div style={{textAlign:"center"}}>
            <div className="primaryBackground">
                <div className="container paddingTwoLines">
                <img src={joinMoonProduct} className="careers"/><br/><br/>             
                    <p className ="font32">Join us and work for your future</p>
                    <p className ="font21" style={{color:"white"}}>We don’t hire you as an employee. We hire you to nudge you as an Entrepreneur.</p>                   
                </div>
            </div>
            {/* <div style={{backgroundColor:"#4053AE"}}>
                <a href="https://hackathon.moonproduct.in/" target="_blank" style={{textDecoration:"none"}}>
                    <p className="font16"style={{textAlign:"center",color:"#ffffff",margin:"-1px",padding:4 }}>Front-end developers! Apply asap. Countdown started. Selected 100 will get an e-mail on Feb 22nd 2020. 2 Rounds. 3 Winners. Participate in Scriptathon!! REGISTER NOW</p>
                </a> 
            </div>    */}
            <div className="secondaryBackground">
                <div className="container padding64">
                    <p className="font29" style={{color:"#262C36"}}>Moon Advantage</p>
                    <p className="font21">Here's why Your Career at MoonProduct will be Like Nowhere Else.<br/><br/></p>
                    <div style={{display:"flex",flexWrap:"wrap"}}>
                        <div className="moonadvantage">
                            <img src={advantage1} style={{height:220}}/>
                            <p className="font21"><strong>Work Anywhere</strong></p>
                            <p className="font19 secondaryColor">Creativy matters. Finish the assigned work and attend the meeting at the office.</p>                    
                        </div>
                        <div className="moonadvantage">
                            <img src={advantage2} style={{height:220}}/>
                            <p className="font21"><strong>Build Your Own Idea</strong></p>
                            <p className="font19 secondaryColor">Apart from work, you can develop your own idea and we’ll help you.</p>                    
                        </div>
                        <div className="moonadvantage">
                            <img src={advantage3} style={{height:220}}/>
                            <p className="font21"><strong>Intensive Learning</strong></p>
                            <p className="font19 secondaryColor">We are obsessed about what you have learned from each and every project.</p>                    
                        </div> 
                        <div className="moonadvantage">
                            <img src={advantage4} style={{height:220}}/>
                            <p className="font21"><strong>Be A Teacher</strong></p>
                            <p className="font19 secondaryColor">Inspire and help aspiring individuals to learn the skill. We believe you will learn a lot by teaching.</p>       
                        </div> 
                        <div className="moonadvantage">
                            <img src={advantage5} style={{height:220}}/>
                            <p className="font21"><strong>Run A Start-Up</strong></p>
                            <p className="font19 secondaryColor">In your domain, you will be given resources to procure projects, deliver the results and get paid in addition to your salary.</p>       
                        </div>
                        <div className="moonadvantage">
                            <img src={advantage6} style={{height:220}}/>
                            <p className="font21"><strong>Be A Leader</strong></p>
                            <p className="font19 secondaryColor">We conduct month on month discussions on latest advancements &amp; we adopt to new technogy agressively.</p>       
                        </div>                        
                    </div>
                </div>
            </div>
            <div className="primaryBackground">
                <a name="wecando"></a>
                <div className="container paddingTwoLines">
                        <p className="font29">Vision</p>
                        <p className="font21" style={{color:"white"}}>MoonProduct is focused on growing entrepreneurship and we believe entrepreneurs will change the world for better.</p>                
                </div>
            </div>
            <div id="webelieve" className="secondaryBackground">
                <a name="openposition"></a>
                <div className="container padding64">
                    <div id ="savedphone">
                    <p className="font29" style={{color:"#262C36"}}>Current Opening (Bangalore)<br/><br/></p>
                    <div style ={{textAlign:"left"}}>
                        <p className="font21"><strong>Full Stack Software Developer</strong></p>
                        <p className="font19 secondaryColor">MoonProduct helps dreamers to build their ideas. MoonProduct is focused on growing entrepreneurship and we believe entrepreneurs will change the world for better.</p><br/>
                        <p className="font19 secondaryColor">We are looking for a full stack software developer who is strong in Python/Java and web technologies. Having knowledge about jQuery or ReactJs are the added advantage. He/She should be crazy about solving complex logic and eager learn about developing awesome user interface with an eye for detail, colour, typography, interaction and beauty.</p><br/>
                        <p className="font21" >Your Moon Mission:</p>
                        <ol>
                            <li className="font19 secondaryColor">Finding efficient and scalable solutions for all product problems</li>
                            <li className="font19 secondaryColor">Developing robust and scalable Android and iOS mobile apps &amp; web application</li>
                            <li className="font19 secondaryColor">Do high level technical design with guidance, functional modelling, break-down of module by thinking platforms and re-use.</li>
                        </ol>                    
                    </div>
                        <p className="font19 secondaryColor">Chat with us<br/><a href="https://wa.me/919791848091" target="_blank"><img src={WhatsApp}/></a></p>
                    </div>
                </div>
            </div>            
        </div>
        <Footer/>
    </div>
);

export default Home;