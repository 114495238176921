import React from 'react';
import Menu from '../../components/menu';
import Footer from '../../components/footer';

const Contactuspage = () => (
    <div>
        {document.title = "Contact us page in html | code written by MoonProduct"}
        {document.description = "Easy copy and use contact us page in html. Get the template for contact us page, modify it. contact us form php is supported by the given code"}
        <Menu/>
        <div style={{textAlign:"left",backgroundColor:"white"}}>
            <div className="container paddingTop">
                <h1>Contact us page in html</h1>
                <p>Easy copy and use contact us page in HTML. If you don't know how to hook this up to either PHP or Java, go to <a href="https://leads.moonproduct.in/pricing" target="_blank">Contact Us Form with backend</a> to get widget code which you can integrate directly to the website.</p>

                <iframe height="265" style={{width: "100%", height:560}} scrolling="no" title="Contact us page in html" src="https://codepen.io/sciencevivek/embed/zYvLwEp?height=265&theme-id=dark&default-tab=result" frameborder="no" allowtransparency="true" allowfullscreen="true">
                See the Pen <a href='https://codepen.io/sciencevivek/pen/zYvLwEp'>Contact us page in html</a> by Vivek
                (<a href='https://codepen.io/sciencevivek'>@sciencevivek</a>) on <a href='https://codepen.io'>CodePen</a>.
                </iframe>

                <p className="codepen" dataHeight="265" dataThemeId="dark" dataDefaultTab="result" dataUser="sciencevivek" dataSluHash="zYvLwEp" style={{height: 265, boxSizing: "border-box", display: "flex", alignItems: "center", justifyContent: "center", border: "2px solid", margin: "1em 0", padding: "1em"}} dataPenTitle="Contact us page in html">
                <span>See the Pen <a href="https://codepen.io/sciencevivek/pen/zYvLwEp">
                Contact us page in html</a> by Vivek (<a href="https://codepen.io/sciencevivek">@sciencevivek</a>)
                on <a href="https://codepen.io">CodePen</a>.</span>
                </p>
                <script async src="https://static.codepen.io/assets/embed/ei.js"></script> 

                <p>Sample Widget from <a href="https://leads.moonproduct.in/pricing" target="_blank">Leads MoonProduct</a></p>
                <iframe src="https://development.moonproduct.in?id=d06d5d138b3621228b48&form=8" frameBorder="0" width="100%" height="400"> </iframe>         
            </div>
        </div>
        <Footer/> 
        {/* <div className="sticky">
            <CtaViral1 content={"Need help in designing and communicating with your visiting card?"} cta={"GET FREE VISTING CARD"} link={""}/>
        </div>  */}

    </div>
);

export default Contactuspage;