import React from 'react';
import Menu from '../../components/menu';
import Footer from '../../components/footer';

const Facebookshop = () => (
    <div>
        {document.title = "Contact us page in html | code written by MoonProduct"}
        {document.description = "Easy copy and use contact us page in html. Get the template for contact us page, modify it. contact us form php is supported by the given code"}
        <Menu/>
        <div style={{textAlign:"left",backgroundColor:"white"}}>
            <div className="container paddingTop">
                <h1>How to set-up Facebook Shop</h1>
                <h2>Setup using Instagram</h2>
                <ul>
                    <li>From Instagram, go to Settings.</li>
                    <li>In the list, click on Business.</li>
                    <li>In the list, click on Sign up for shopping.</li>
                    <li>You will see a page with title "Sign Up for Instagram Shopping", click on Continue.</li>
                    <li>It will prompt you to connect with existing Facebook page or create new Facebook page.</li>
                    <li>After connecting, it will take you to a page with title "Create or Connect a Catalog" which has following options Facebook Catalog Manager, Shopify, BigCommerce, Magento, WooCommerce, Facebook Shop.</li>
                    <li>Choose one and continue.</li>
                </ul>
                <h2>Setup using Facebook Catalog</h2>
                <ul>
                    <li>If you want to use Facebook Catalog, go the URL <a href="https://www.facebook.com/products" target="_blank">https://www.facebook.com/products</a> and create eCommerce Catalog and add products to it. </li>
                    <li>In Catalog Manager, select the catalog you want to use. This should take you to the Diagnostics page for that catalog.</li>
                    <li>Under ways to Use Your Catalog, select Instagram Shopping.</li>
                    <li>Choose Connect Your Instagram Business Profile and select Connect Profile.</li>
                </ul>
                <h2>Setup using Shopify</h2>
                <ul>
                    <li>Go to Settings in bottom left.</li>
                    <li>Choose Sales Channels</li>
                    <li>Add Facebook Channel</li>
                    <li>Once you added Facebook Channel, add Instagram Channel</li>
                    <li>Select Facebook Page that is connected to your Instagram account to enable the Cataog for Instagram.</li>
                </ul>
                <h2>Setup using Facebook Page</h2>
                <ul>
                    <li>On your page, click the Shop tab. </li>
                    <li>If you don't see this tab, you'll need to change your Page template to the Shopping template to continue. </li>
                    <li>Fill in the details for your business.</li>
                    <li>Once you've added a shop section, you will be able to manually add products. </li>
                </ul>
                <p>If you get struck somewhere, ping us in chat in bottom right corner of this page, we will try our best to help you.</p>
                <iframe src="https://development.moonproduct.in?id=d06d5d138b3621228b48&form=8" frameBorder="0" width="100%" height="400"> </iframe>         
            </div>
        </div>
        <Footer/> 
        {/* <div className="sticky">
            <CtaViral1 content={"Need help in designing and communicating with your visiting card?"} cta={"GET FREE VISTING CARD"} link={""}/>
        </div>  */}

    </div>
);

export default Facebookshop;