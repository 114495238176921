import React from 'react';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import CtaViral from '../../components/cta-viral';
import card1 from "../../images/business-card-moonproduct.svg";
import card2 from "../../images/My Business Card .png";
import card3 from "../../images/mm.png";
import card4 from "../../images/Inc.png";
import card5 from "../../images/Pixel.png";
import card6 from "../../images/Background.png";
import card7 from "../../images/v1.png";
import card8 from "../../images/v2.png";
import card9 from "../../images/v3.png";
import card10 from "../../images/v4.png";
import card11 from "../../images/search.png";
import card13 from "../../images/Button.png"
import card12 from "../../images/search.png"
import "./index.css";

const CtaViral1=(props)=>(
    <div className="button-cta padding10 vrlps-trigger" style={{textAlign:"center",backgroundColor:"navy"}}>
        
            <p style={{color:"White",textAlign:"center"}} className="font21">{props.content}</p>  
            <a><button className="button" type="button">{props.cta}</button></a>              
        
    </div>
);

const Business = () => (
    <div>
        {document.title = "Business Cards / Visiting Cards | MoonProduct"}
        <Menu/>
        <div style={{textAlign:"left",backgroundColor:"white"}}>
            <div className="container paddingTop">
                <h1 className="main-header black left">Business Cards / Visiting Cards</h1>
                <h2 className="sub-header black" style={{color:"#262C36"}}>What to put on a business card?</h2>
                <img src={card1} style={{display: "block",marginLeft:"auto",marginRight: "auto"}} className="resources" alt="What to put on Business Cards or Visiting Cards?"/>
                <p className="p-grey">A Good Business Card is not just a tool for giving your information. Its an extension of your brand that conveys the right information of you. So, here is the tip to design a basic Business Card.</p>
                <br/>
                <h2 className="sub-header blue">Business Card Size</h2>
                <p className="p-grey">Different countries have different sizes. Here are the Standard sizing for the Business Cards. </p>  
                <p className="p-grey">This is applicable for both horizontal/Vertical Cards.</p>
                <ul>
                    <li><p className="p-grey">Most common size for <span className="blue">Normal</span> Cards is <span className="blue">54mm * 92mm</span> with full bleed and trim size is <span className="blue">51mm * 89mm.</span> Designers use <span className="blue">1083px * 633px</span> for designing visiting card</p> 
                    </li>
                    <li><p className="p-grey">Most common size for <span className="blue">Square</span> Size is <span className="blue">67mm * 67mm</span> with full bleed and trim size is <span className="blue">65mm * 65mm</span> / Designers use <span className="blue">788px * 788px</span> for designing visiting card.</p></li>
                </ul>
                <img src={card2} style={{display: "block",marginLeft:"auto",marginRight: "auto"}} className="resources"/>
                <br/>
                <h2 className="sub-header blue">Standard Sizing</h2>
                <div style={{display:"flex" ,flexWrap:"wrap" ,marginLeft:"10%",justifyItems:"center"}}>
                    <img src={card3} style={{display: "flex",marginLeft: "5%"}} className="resources"/>
                    <img src={card4} style={{display: "flex",marginLeft: "5%"}} className="resources"/>
                    <img src={card5} style={{display: "flex",marginLeft: "10%"}} className="resources"/>
                </div>
                <br/>
                <h2 className="sub-header blue">Business Card Paper Types</h2> 
                <p className="p-grey">There are four different types of Business Card Finishing</p>
                <p><span className="card-types blue">Matte</span> - <span className="grey-italic">Non-Shinny, Rough</span></p>
                <p><span className="card-types blue">Glossy</span> - <span className="grey-italic">Shinny</span></p>
                <p><span className="card-types blue">Embossed</span> - <span className="grey-italic">Special Touch with Raised Ink</span></p>
                <p><span className="card-types blue">Die Cut</span> - <span className="grey-italic">Customized Cards according to Logo</span></p>
                <p><span className="card-types blue">Die Cut Popup</span> - <span className="grey-italic">Popping out of a logo or Tagline</span></p>
                <br/>
            </div>
            <div className="visting">
                <h1 className="main-header center" style={{paddingTop:"220px"}}>Business Card Designs</h1>
                <div className="row">
                    <div className="column">
                        <img src={card7} style={{marginTop:"20px"}} className="resources"/>
                        <img src={card8} style={{marginTop:"20px"}} className="resources"/>
                        <img src={card9} style={{marginTop:"20px"}} className="resources"/>
                        <img src={card10} style={{marginTop:"20px"}} className="resources"/>
                    </div>
                </div>
            </div>
            <br/>
            <div className="container paddingTop">
                <div>
                    <h2 className="sub-header blue">Business Card Logo and Tagline</h2>
                    <p className="p-grey">For Logo Generator - Brandcrowd</p>
                    <p className="p-grey">For Tagline Generator - Shopify Free Slogan Maker</p>                    
                </div>
                <br/>                
            </div>   
            {/* <div className="visting1 justify-items">                                   
                    <img src={card12}/>  
                    <div className = "query">
                        <h4 className="main-header" style={{fontWeight:"400", lineHeight: "50px"}}>Need help in designing and communicating with your visiting card?</h4>
                        <button className="button-text">GET FREE VISITING CARD</button>
            </div>    */}
            {/* <CtaViral content={"Need help in designing and communicating with your visiting card?"} cta={"GET FREE VISTING CARD"} link={""}/>                 */}
        </div>
        {/* <CtaViral content={"Need help in designing and communicating with your visiting card?"} cta={"GET FREE VISTING CARD"} link={""}/> */}
        <Footer/> 
        {/* <div className="sticky">
            <CtaViral1 content={"Need help in designing and communicating with your visiting card?"} cta={"GET FREE VISTING CARD"} link={""}/>
         </div>  */}

    </div>
);

export default Business;