import React from 'react';
import './index.css';
import logo from '../../logo.svg';

const menu = props => (
    <header className="menu">
        <div className="menu-content">
            <nav className="menu-nav">
                <div></div>
                <div className="menu-logo">
                    <a href="/"><img src={logo} className="App-logo" alt="MoonProduct" style={{height:64,width:"100%"}}/></a>                
                </div>
                <div className="menu-spacer"></div>
                <div className="menu-items">
                    <ul>
                        
                        <li><a href="/#what-we-do">What we do?</a></li>
                        {/* <li><a href="/resources-listing">Resources</a></li> */}
                        <li><a href="/careers">Careers</a></li>
                       
                    </ul>                
                </div>
                <div className="menu-button">
                    <a href="https://calendly.com/moonproduct/15min"><button className="button" type="button" style={{fontSize:12,padding:"6px 10px"}}>Get Connected</button></a>  
                </div>
            </nav>            
        </div>
    </header>

);

export default menu;