import React from 'react';
import './index.css';

const Cta = (props) => (
    <div className="cta" style={{backgroundColor:"#F0F0F0"}}>
        <div className="container center">
            <div className="button-cta padding64">
                <p style={{color:"#262C36"}} className="font21">{props.content}</p>  
                <a href={props.link}><button className="button" type="button">{props.cta}</button></a>              
            </div>
        </div>
    </div>
);

export default Cta;