import React from 'react';
import client1 from '../../images/client1.svg';
import client2 from '../../images/client2.svg';
import client3 from '../../images/client3.svg';
import client4 from '../../images/client4.svg';
import client5 from '../../images/client5.svg';
import client6 from '../../images/client6.svg';
import client7 from '../../images/client7.svg';
import client8 from '../../images/client8.svg';
import client9 from '../../images/client9.svg';
import client10 from '../../images/client10.svg';

const Client = () => (
    <div className="client">
        <div className="container center" style={{padding:80}}>
            <p className="font29" style={{color:"#262C36"}}>Our Clients</p>
            <div style={{padding:"32px 0px 0px 0px"}}>
                <img style={{padding:20}} style={{padding:20}} src={client1} alt="MoonProduct Client - EnCloudEn"/>
                <img style={{padding:20}} src={client2} alt="MoonProduct Client - eMag Solutions"/>
                <img style={{padding:20}} src={client3} alt="MoonProduct Client - byzo"/>
                <img style={{padding:20}} src={client4} alt="MoonProduct Client - ZEPNURhealth"/>
                <img style={{padding:20}} src={client5} alt="MoonProduct Client - Fasmo"/>   
                <img style={{padding:20}} src={client6} alt="MoonProduct Client - ESTSglobal"/>     
                <img style={{padding:20}} src={client7} alt="MoonProduct Client - Lakshiyaa"/>      
                <img style={{padding:20}} src={client8} alt="MoonProduct Client - Zaina"/>  
                <img style={{padding:20}} src={client10} alt="MoonProduct Client - Bridge Auto Tech"/>  
                <img style={{padding:20}} src={client9} alt="MoonProduct Client - Nefa"/>          
            </div>
        </div>
    </div>
)

export default Client;
